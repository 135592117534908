import Vue from "vue";
import _ from "lodash";
import usersUtils from "@/utils/users";
import routerUtils from "@/utils/router";
import session from "@/utils/localStorageManager";
//import storage from "@/utils/localStorageManager";
import { getEnabledFeaturesForUser } from "@/api/appfeatures";
import echo from "@/echo";
import { sortObjectEntries } from "@/utils/collection";
import { getLatestAnnouncements, loadDashboardStats } from "@/api/dashboard";
import { TYPES } from "@/constants/dashboard";
import can from "@/canChecks";
import router from "@/router";
import { formatTimeStampWithTz } from "@/utils/time";
import { TIMEZONE_SOURCE } from "@/constants/timezone";
import { accountNameLabel } from "@/utils/accounts";
import newrelic from "@/utils/newrelicWrapper";
import gainsightpx from "@/utils/GainSightPXWrapper.js";
import util from "store/src/util";
import { REMEMBER_ME_COOKIE } from "@/constants/cookies";

const state = {
  userEmail: "",
  userType: null,
  userAccountSwitcherOptions: [],
  selectedServiceProviderId: "all",
  selectedAccountId: "all",
  selectedAccountDomain: null,
  userApplicationViewScope: "",
  isInCanvas: false,
  darkTheme: false,
  token: "",
  enabled_features: [],
  userRole: null,
  virtualAgentUsage: {},
  chatbotUsage: {},
  callVolumeSinceLastMonth: {},
  announcements: {},
  globalContentModalIsOpen: false,
  subdomain: "",
  logo_url: "",
  redirected: false,
  isLoadingOptions: false,
  defaultDisplayDateFormat: "",
  displayDateFormats: [],
  displayDateFormat: "",
  displayTimeFormat: "HH:mm",
  timezone: "",
  timezoneSource: "",
  timezone_ac_sp: "",
  ctEnabled: false,
  backendTimezone: "",
  jurisdictionId: ""
};

export const mutations = {
  SET_APP_USER_TYPE_AS(state, userType) {
    state.userType = userType;
  },
  SET_USER_ACCOUNT_SWITCHER_OPTIONS: (state, options) => {
    state.userAccountSwitcherOptions = options;
  },
  CHANGE_SELECTED_SERVICE_PROVIDER: (state, serviceProviderId) => {
    state.selectedServiceProviderId = serviceProviderId;
    newrelic.setCustomAttribute("spId", serviceProviderId);
    gainsightpx.setCustomAttribute("spId", serviceProviderId);
  },
  CHANGE_SELECTED_ACCOUNT: (state, accountId) => {
    state.selectedAccountId = accountId;
    newrelic.setCustomAttribute("acId", accountId);
    gainsightpx.setCustomAttribute("acId", accountId);
  },
  CHANGE_SELECTED_ACCOUNT_DOMAIN: (state, accountDomain) => {
    state.selectedAccountDomain = accountDomain;
    newrelic.setCustomAttribute("domainId", accountDomain);
    gainsightpx.setCustomAttribute("domainId", accountDomain);
  },
  SET_USER_SCOPE_IN_APPLICATION: (state, scope) => {
    state.userApplicationViewScope = scope;
    newrelic.setCustomAttribute("userApplicationViewScope", scope);
  },
  SET_JURISDICTION_ID: (state, jurisdictionId) => {
    state.jurisdictionId = jurisdictionId;
    gainsightpx.setCustomAttribute("jurisdictionId", jurisdictionId);
  },
  SET_LOGGED_IN_USER_EMAIL: (state, email) => {
    state.userEmail = email;
    newrelic.setCustomAttribute("userEmail", email);
  },
  SET_CT_ENABLED: (state, ctEnabled) => {
    state.ctEnabled = ctEnabled;
    newrelic.setCustomAttribute("isCloudTrain", ctEnabled ? 1 : 0);
  },
  TOGGLE_CANVAS_MODE: (state, isInCanvas) => {
    state.isInCanvas = isInCanvas;
  },
  TOGGLE_OPTIONS_LOADING: (state, isLoading) => {
    state.isLoadingOptions = isLoading;
  },
  TOGGLE_DARK_THEME: state => {
    state.darkTheme = !state.darkTheme;
  },
  SET_DARK_THEME: (state, val) => {
    state.darkTheme = val;
  },
  UPDATE_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_ENABLED_FEATURES: (state, val) => {
    state.enabled_features = val;
  },
  SET_USER_ROLE: (state, val) => {
    state.userRole = val;
    newrelic.setCustomAttribute("userRole", val);
  },
  INITIALIZE_VIRTUAL_AGENT_USAGE: (state, accountIds) => {
    let virtualAgentUsage = {};
    _.map(accountIds, accountId =>
      Object.assign(virtualAgentUsage, { [accountId]: 0 })
    );
    state.virtualAgentUsage = virtualAgentUsage;
  },
  UPDATE_VIRTUAL_AGENT_USAGE: (state, { acId, usage }) => {
    state.virtualAgentUsage[acId] = usage;
  },
  INITIALIZE_CHATBOT_USAGE: (state, accountIds) => {
    let chatbotUsage = {};
    _.map(accountIds, accountId =>
      Object.assign(chatbotUsage, {
        [accountId]: {
          usagePercentage: 0,
          usagesLeft: 0,
          isUsingBurstUsages: false,
          burstUsagesLeft: 0,
          featureBurstLimit: 0
        }
      })
    );
    state.chatbotUsage = chatbotUsage;
  },
  UPDATE_CHATBOT_USAGE: (
    state,
    {
      acId,
      usagePercentage,
      usagesLeft,
      isUsingBurstUsages,
      burstUsagesLeft,
      featureBurstLimit
    }
  ) => {
    state.chatbotUsage = state.chatbotUsage || {};
    state.chatbotUsage[acId] = {
      usagePercentage: usagePercentage,
      usagesLeft: usagesLeft,
      isUsingBurstUsages: isUsingBurstUsages,
      burstUsagesLeft: burstUsagesLeft,
      featureBurstLimit: featureBurstLimit
    };
  },
  INITIALIZE_CALL_VOLUME: (state, accountIds) => {
    let callVolumeSinceLastMonth = {};
    _.map(accountIds, accountId =>
      Object.assign(callVolumeSinceLastMonth, { [accountId]: 0 })
    );
    state.callVolumeSinceLastMonth = callVolumeSinceLastMonth;
  },
  UPDATE_CALL_VOLUME_USAGE: (state, { acId, call_volume }) => {
    state.callVolumeSinceLastMonth[acId] = call_volume;
  },
  SET_ANNOUNCEMENTS(state, announcements) {
    let initialAnnouncements = {};
    _.map(TYPES, type => (initialAnnouncements[type] = []));
    state.announcements = Object.assign(initialAnnouncements, announcements);
  },
  SET_GLOBAL_CONTENT_MODAL_IS_OPEN: (state, val) => {
    state.globalContentModalIsOpen = val;
  },
  SET_APP_SUBDOMAIN(state, subdomain) {
    state.subdomain = subdomain;
  },
  SET_APP_LOGO(state, logo_url) {
    state.logo_url = logo_url;
  },
  SET_REDIRECTED(state, redirected) {
    state.redirected = redirected;
  },

  SET_DISPLAY_DATE_FORMATS: (state, displayDateFormats) => {
    state.displayDateFormats = displayDateFormats;
  },

  SET_DEFAULT_DISPLAY_DATE_FORMAT: (state, defaultDisplayDateFormat) => {
    state.defaultDisplayDateFormat = defaultDisplayDateFormat;
  },

  SET_DISPLAY_DATE_FORMAT: (state, { user, getters }) => {
    // set display date format based off user
    let displayDateFormat = user.display_date_format;

    // if user does not have a display date set, then try grab from the account
    if (
      _.isEmpty(displayDateFormat) &&
      !_.isEmpty(getters.selectedAccount) &&
      !_.isEmpty(getters.selectedAccount.ac_setting) &&
      !_.isEmpty(getters.selectedAccount.ac_setting.display_date_format)
    ) {
      displayDateFormat =
        getters.selectedAccount.ac_setting.display_date_format;
    }

    // if user and account don't have display date set, then try grab off service provider
    if (
      _.isEmpty(displayDateFormat) &&
      !_.isEmpty(getters.selectedServiceProvider)
    ) {
      // if account is selected then go find matching account on service provider
      if (
        !_.isEmpty(getters.selectedAccount) &&
        !_.isEmpty(getters.selectedServiceProvider.accounts)
      ) {
        let matchingAccount = _.find(
          getters.selectedServiceProvider.accounts,
          function(account) {
            return (
              parseInt(account.ac_id) ===
              parseInt(getters.selectedAccount.ac_id)
            );
          }
        );

        if (
          !_.isEmpty(matchingAccount) &&
          !_.isEmpty(matchingAccount.ac_setting) &&
          !_.isEmpty(matchingAccount.ac_setting.display_date_format)
        ) {
          displayDateFormat = matchingAccount.ac_setting.display_date_format;
        }
      }

      // no match on sp - > account then go look up the service provider setting if service provider selected
      if (
        _.isEmpty(displayDateFormat) &&
        !_.isEmpty(getters.selectedServiceProvider.sp_setting) &&
        !_.isEmpty(
          getters.selectedServiceProvider.sp_setting.display_date_format
        )
      ) {
        displayDateFormat =
          getters.selectedServiceProvider.sp_setting.display_date_format;
      }
    }

    // if no match on anything drop back to the default
    if (_.isEmpty(displayDateFormat)) {
      displayDateFormat = state.defaultDisplayDateFormat;
    }

    state.displayDateFormat = displayDateFormat;
  },
  SET_TIMEZONE: (state, { user, getters }) => {
    // set default timezone based off user
    let timezone = user.default_timezone;
    let timezoneSource = TIMEZONE_SOURCE.USER_PROFILE;
    let timezone_ac_sp = "";
    let ac_timezone = "";
    let sp_timezone = "";

    // if user does not have a display date set, then try grab from the account
    if (
      !_.isEmpty(getters.selectedAccount) &&
      !_.isEmpty(getters.selectedAccount.ac_setting) &&
      !!getters.selectedAccount.ac_setting.default_timezone
    ) {
      ac_timezone = getters.selectedAccount.ac_setting.default_timezone;
      if (!timezone) {
        timezone = ac_timezone;
        timezoneSource = TIMEZONE_SOURCE.ACCOUNT;
      }
      if (!timezone_ac_sp) {
        timezone_ac_sp = ac_timezone;
      }
    }

    // if user and account don't have timezone set, then try grab off service provider
    if (!_.isEmpty(getters.selectedServiceProvider)) {
      // if account is selected then go find matching account on service provider
      if (
        !_.isEmpty(getters.selectedAccount) &&
        !_.isEmpty(getters.selectedServiceProvider.accounts)
      ) {
        let matchingAccount = _.find(
          getters.selectedServiceProvider.accounts,
          function(account) {
            return (
              parseInt(account.ac_id) ===
              parseInt(getters.selectedAccount.ac_id)
            );
          }
        );

        if (
          !_.isEmpty(matchingAccount) &&
          !_.isEmpty(matchingAccount.ac_setting) &&
          !!matchingAccount.ac_setting.default_timezone
        ) {
          ac_timezone = matchingAccount.ac_setting.default_timezone;
          if (!timezone) {
            timezone = ac_timezone;
            timezoneSource = TIMEZONE_SOURCE.ACCOUNT;
          }
          if (!timezone_ac_sp) {
            timezone_ac_sp = ac_timezone;
          }
        }
      }

      // no match on sp - > account then go look up the service provider setting if service provider selected
      if (
        !_.isEmpty(getters.selectedServiceProvider.sp_setting) &&
        !!getters.selectedServiceProvider.sp_setting.default_timezone
      ) {
        sp_timezone =
          getters.selectedServiceProvider.sp_setting.default_timezone;
        if (!timezone) {
          timezone = sp_timezone;
          timezoneSource = TIMEZONE_SOURCE.SERVICE_PROVIDER;
        }
        if (!timezone_ac_sp) {
          timezone_ac_sp = sp_timezone;
        }
      }
    }

    // if no match on anything drop back to the default
    if (!timezone) {
      timezone = "UTC";
      timezoneSource = "";
    }

    if (!timezone_ac_sp) {
      timezone_ac_sp = "UTC";
    }

    state.timezone = timezone;
    state.timezone_ac_sp = timezone_ac_sp;
    state.timezoneSource = timezoneSource;
  },

  SET_BACKEND_TIMEZONE: (state, backendTimezone) => {
    state.backendTimezone = backendTimezone;
  }
};

export const getters = {
  appRedirected(state) {
    return state.redirected;
  },

  selectedServiceProviderAccountCombination(state) {
    return state.selectedServiceProviderId + "|" + state.selectedAccountId;
  },

  /** Checking if the user is in system scope
   * @param state
   * @returns boolean
   */
  inSystemScope(state) {
    return (
      state.selectedServiceProviderId === "all" &&
      state.selectedAccountId === "all"
    );
  },

  /** Checking if the user is in sp scope
   * @param state
   * @returns boolean
   */
  inSpScope(state) {
    return (
      state.selectedServiceProviderId !== "all" &&
      state.selectedAccountId === "all"
    );
  },

  selectedServiceProvider(state) {
    return _.find(state.userAccountSwitcherOptions, {
      sp_id: state.selectedServiceProviderId
    });
  },

  /**
   * all service provider id and name
   * @param state
   * @returns {[]|*|[{sp_name: string, sp_id: number, logopath: string, scope: string, accounts: [{ac_name: string, sp_name: string, sp_id: number, logopath: string, scope: string, ac_id: number}]}]}
   */
  serviceProvidersIdAndName(state) {
    return _.map(
      state.userAccountSwitcherOptions,
      userAccountSwitcherOption => {
        return {
          sp_id: userAccountSwitcherOption.sp_id,
          sp_name: userAccountSwitcherOption.sp_name
        };
      }
    );
  },

  serviceProviderIds(state) {
    return _.map(state.userAccountSwitcherOptions, "sp_id");
  },

  // should not be used anywhere else
  // has got a specific purpose
  accountIds(state) {
    return _.flatMap(
      _.map(state.userAccountSwitcherOptions, sp =>
        _.concat(["all_sp_" + sp.sp_id], _.map(sp.accounts, "ac_id"))
      )
    );
  },

  accounts(state) {
    return _.flatMap(
      _.map(state.userAccountSwitcherOptions, sp => sp.accounts)
    );
  },

  accountsForCreate(state) {
    let accounts = _.get(
      state,
      "userAccountSwitcherOptions[0].accounts",
      "all"
    );
    return accounts
      ? accounts.map(account => {
          return {
            label: account.ac_name,
            value: {
              ac_id: account.ac_id,
              mapping_scope: "ac",
              sp_id: account.sp_id
            }
          };
        })
      : [];
  },

  accountName(state, getters) {
    return ac_id => {
      let account = _.find(
        getters.accounts,
        account => account.ac_id === ac_id
      );

      if (_.isEmpty(account)) {
        return "";
      }

      return _.get(account, "ac_name", "");
    };
  },

  top3AccountsByVirtualAgentUsage(state, getters) {
    if (!getters.selectedServiceProvider) {
      return [];
    }
    let accounts = getters.selectedServiceProvider.accounts;
    let top3AccountIds = sortObjectEntries(
      _.pick(
        state.virtualAgentUsage,
        _.map(accounts, account => account.ac_id.toString())
      ),
      3
    );
    return _.map(top3AccountIds, acId => {
      const account = _.find(accounts, {
        ac_id: +acId
      });
      return {
        ac_name: account.ac_name,
        ac_id: account.ac_id,
        usage: state.virtualAgentUsage[account.ac_id]
      };
    });
  },

  top3AccountsByChatbotUsage(state, getters) {
    if (!getters.selectedServiceProvider) {
      return [];
    }
    let accounts = getters.selectedServiceProvider.accounts;

    const transformedObject = {};
    Object.entries(state.chatbotUsage).forEach(([key, value]) => {
      transformedObject[key] = value.usagePercentage;
    });

    let top3AccountIds = sortObjectEntries(
      _.pick(
        transformedObject,
        _.map(accounts, account => account.ac_id.toString())
      ),
      3
    );
    return _.map(top3AccountIds, acId => {
      const account = _.find(accounts, {
        ac_id: +acId
      });
      return {
        ac_name: account.ac_name,
        ac_id: account.ac_id,
        usage: state.chatbotUsage[account.ac_id]
      };
    });
  },

  userHasSPScopeToAc(state, getters) {
    const selectedAc = getters.selectedAccount;
    if (!_.isEmpty(selectedAc)) {
      return selectedAc.scope === "sp";
    }
    return false;
  },

  selectedAccount(state, getters) {
    const serviceProvider = getters.selectedServiceProvider;
    if (serviceProvider !== undefined) {
      return _.find(serviceProvider.accounts, {
        ac_id: state.selectedAccountId
      });
    }
    return undefined;
  },

  userRoleHasSystemRoleOrScope() {
    let user = Vue.auth.user();
    let roleWithScope = _.get(user.roles_with_scope, 0);
    let result = false;
    if (_.isEmpty(roleWithScope)) {
      result = false;
    } else {
      result =
        roleWithScope.scope === "system" || roleWithScope.name === "system";
    }

    return result;
  },

  selectedServiceProviderName(state, getters) {
    const { selectedServiceProvider } = getters;

    if (selectedServiceProvider !== undefined) {
      return selectedServiceProvider.sp_name;
    }
    return __("All Service Providers");
  },

  selectedAccountName(state, getters) {
    const selectedAccount = getters.selectedAccount;
    if (selectedAccount !== undefined) {
      return accountNameLabel(selectedAccount);
    }
    return __("All Accounts");
  },

  logoToShow(state, getters) {
    const { selectedServiceProvider } = getters;

    if (_.isEmpty(selectedServiceProvider)) {
      return "/Five9Logo.jpg";
    }

    const { selectedAccount } = getters;

    let logo = _.isEmpty(selectedAccount)
      ? selectedServiceProvider.logopath
      : selectedAccount.logopath;

    return logo || selectedServiceProvider.logopath || "/Five9Logo.jpg";
  },

  userAccountsFor(state) {
    return sp_id => {
      if (sp_id === "all") {
        return [];
      }
      return _.find(state.userAccountSwitcherOptions, { sp_id }).accounts;
    };
  },

  hasAccessToMultipleServiceProviders(state) {
    return state.userAccountSwitcherOptions.length > 1;
  },

  hasMultipleAccountsInServiceProvider(state, getters) {
    return sp_id => {
      return getters.userAccountsFor(sp_id).length > 1;
    };
  },

  /**
   * get display date formats
   * @param state
   * @returns {any}
   */
  displayDateFormats: state => state.displayDateFormats,

  /**
   * get display date format
   * @param state
   * @returns {any}
   */
  displayDateFormat: state => state.displayDateFormat,

  /**
   * timezone
   * @param state
   */
  timezone: state => state.timezone,

  /**
   * source for computing timezone
   * @param state
   */
  timezoneSource: state => state.timezoneSource,

  timezone_ac_sp: state => state.timezone_ac_sp,

  /**
   * get display date time format
   * @param state
   * @returns {" HH:mm:ss"|"displayDateFormat HH:mm:ss"|`${*} HH:mm:ss`}
   */
  displayDateTimeFormat: state => {
    return `${state.displayDateFormat} ${state.displayTimeFormat}`;
  },

  /**
   * get the full display date time seconds format
   * @param state
   * @param getters
   * @returns {"displayDateTimeFormat:ss"|`${function(*): string}:ss`|`${*}:ss`}
   */
  displayDateTimeSecondsFormatMoment: (state, getters) => {
    return `${getters.displayDateFormatMoment.toUpperCase()} ${
      state.displayTimeFormat
    }:ss`;
  },

  /**
   * get the full display date time seconds format
   * @param state
   * @param getters
   * @returns {"displayDateTimeFormat:ss"|`${function(*): string}:ss`|`${*}:ss`}
   */
  displayDateTimeSecondsFormat: (state, getters) => {
    return `${getters.displayDateTimeFormat}:ss`;
  },

  /**
   * get display date format for moment
   * @param state
   * @returns {string}
   */
  displayDateFormatMoment: state => state.displayDateFormat.toUpperCase(),

  /**
   * get ct enabled flag
   * @param state
   * @returns {string}
   */
  ctEnabled: state => state.ctEnabled,

  /**
   * return a formatted date time based on the timezone and display date format
   * @param state
   * @param getters
   * @returns {function(*, *=): *|string}
   */
  formattedDateTime: (state, getters) => {
    return (
      dateTimeToFormat,
      displayFormat = getters.displayDateTimeSecondsFormatMoment
    ) => {
      return formatTimeStampWithTz(
        dateTimeToFormat,
        getters.timezone,
        displayFormat
      );
    };
  },
  appLocationHost() {
    return window.location.host;
  }
};

export const actions = {
  toggleCanvasMode({ commit }, isInCanvas) {
    commit("TOGGLE_CANVAS_MODE", isInCanvas);
  },

  toggleOptionsLoading({ commit }, isLoading) {
    commit("TOGGLE_OPTIONS_LOADING", isLoading);
  },

  tokenRefreshed({ commit }, token) {
    commit("UPDATE_TOKEN", token);
  },

  setAppSubdomain({ commit }, subdomain) {
    //storage.set("subdomain", subdomain);
    if (subdomain !== undefined && _.isString(subdomain)) {
      session.set("subdomain", subdomain.toLowerCase());
      commit("SET_APP_SUBDOMAIN", subdomain.toLowerCase());
    }
  },

  setSpLogo({ commit }, logo_url) {
    commit("SET_APP_LOGO", logo_url);
  },

  setRedirected({ commit }, redirected) {
    commit("SET_REDIRECTED", redirected);
  },

  /**
   * update user info, need this for when update user profile
   * @param commit
   * @param getters
   * @param user
   */
  updateUserInfo({ commit, getters }, user) {
    commit("SET_DISPLAY_DATE_FORMAT", {
      user,
      getters
    });

    commit("SET_TIMEZONE", {
      user,
      getters
    });
  },

  async generateUserAccountSwitcherOptions(
    { commit, dispatch, state },
    spIdAcId
  ) {
    //setting the cookie like this will work in an iFrame. It did not work in an iframe when using the cookieStorage
    util.Global.document.cookie = REMEMBER_ME_COOKIE;

    const accessibleServiceProviders = usersUtils.formatAccountMappingToPopulateAccountSwitchingOptions(
      Vue.auth.user()
    );

    newrelic.setUserId(
      Vue.auth.user().user_id ? Vue.auth.user().user_id.toString() : null
    );

    commit(
      "SET_CT_ENABLED",
      _.has(Vue.auth.user().meta, "ct_enabled")
        ? Vue.auth.user().meta.ct_enabled
        : false
    );
    commit(
      "SET_DISPLAY_DATE_FORMATS",
      Vue.auth.user().meta.display_date_formats
    );

    commit(
      "SET_DEFAULT_DISPLAY_DATE_FORMAT",
      Vue.auth.user().meta.default_display_date_format
    );

    commit(
      "SET_BACKEND_TIMEZONE",
      _.has(Vue.auth.user().meta, "backend_timezone")
        ? Vue.auth.user().meta.backend_timezone
        : ""
    );

    commit("SET_LOGGED_IN_USER_EMAIL", Vue.auth.user().email);
    commit(
      "SET_USER_ACCOUNT_SWITCHER_OPTIONS",
      _.cloneDeep(accessibleServiceProviders)
    );

    let serviceProviderId = "all";
    let accountId = "all";

    if (
      session.get("appState") &&
      session.get("appState").userEmail === state.userEmail
    ) {
      const {
        selectedServiceProviderId,
        selectedAccountId,
        darkTheme,
        subdomain
      } = session.get("appState");

      const retVal = usersUtils.setServiceProviderIdAndAccountIdForUser(
        _.get(spIdAcId, "sp_id"),
        selectedServiceProviderId,
        accessibleServiceProviders,
        _.get(spIdAcId, "ac_id"),
        selectedAccountId
      );

      serviceProviderId = retVal.serviceProviderId;
      accountId = retVal.accountId;
      commit("SET_DARK_THEME", darkTheme);
      if (subdomain !== "") {
        await dispatch("setAppSubdomain", session.get("subdomain"));
      }
    } else {
      const retVal = usersUtils.setServiceProviderIdAndAccountIdForUser(
        _.get(spIdAcId, "sp_id"),
        undefined,
        accessibleServiceProviders,
        _.get(spIdAcId, "ac_id"),
        undefined
      );
      serviceProviderId = retVal.serviceProviderId;
      accountId = retVal.accountId;
    }

    if (Vue.auth.user().roles) {
      let rolesWithScope = Vue.auth.user().roles_with_scope;

      if (rolesWithScope[0]["scope"] === "system") {
        commit("SET_USER_ROLE", rolesWithScope[0]["name"]);
      } else if (serviceProviderId !== "all") {
        let role = rolesWithScope.find(roleObj => {
          return roleObj["sp_id"] === serviceProviderId;
        });
        commit("SET_USER_ROLE", role["name"]);
      } else {
        commit("SET_USER_ROLE", null);
      }
    }

    await dispatch("changeUserSelectedServiceProviderId", serviceProviderId);
    await dispatch("changeUserSelectedAccountId", accountId);
    await dispatch("getEnabledFeaturesForUser", accountId);

    //on page refresh subdomain will be empty string again so we read from session and retain its value
    dispatch("initializeVirtualAgentUsagesForAccessibleAccounts").then(
      acIds => {
        _.map(acIds, acId => {
          echo
            .channel("account." + acId)
            .listen("VirtualAgentUsageEvent", ({ usage }) => {
              dispatch("updateVirtualAgentUsage", { acId, usage: +usage });
            });
        });
      }
    );

    dispatch("initializeCallVolumeSinceLastMonthForAccessibleAccounts").then(
      () => {
        dispatch("requestDashboardStats");
      }
    );

    dispatch("initializeChatbotUsagesForAccessibleAccounts").then(acIds => {
      _.map(acIds, acId => {
        echo
          .channel("account." + acId)
          .listen(
            "ChatbotUsageEvent",
            ({
              usagePercentage,
              usageLeft,
              isUsingBurstUsages,
              burstUsageLeft,
              featureBurstLimit
            }) => {
              dispatch("updateChatbotUsage", {
                acId,
                usagePercentage: usagePercentage,
                usagesLeft: usageLeft,
                isUsingBurstUsages: isUsingBurstUsages,
                burstUsagesLeft: burstUsageLeft,
                featureBurstLimit: featureBurstLimit
              });
            }
          );
      });
    });

    // dispatch job to do all initial stats loading and announcements
    dispatch("retrieveInitialDashboardContents");

    let user = Vue.auth.user();
    commit(
      "SET_JURISDICTION_ID",
      // eslint-disable-next-line no-prototype-builtins
      user.meta.hasOwnProperty("jurisdiction_id")
        ? user.meta.jurisdiction_id
        : ""
    );
    gainsightpx.setCustomAttribute("id", accountId);
    gainsightpx.setGainSightIdentityUserObject(user);
    gainsightpx.identify();

    await routerUtils.resetRoutes();
    return { serviceProviderId, accountId };
  },

  retrieveInitialDashboardContents({ dispatch }) {
    dispatch("getAnnouncementsToShowInDashboard");
  },

  subscribeToReports({ dispatch, state }) {
    echo
      .channel("reports." + state.selectedAccountId)
      .listen(
        "ReportInstanceStatusUpdateEvent",
        ({ reportInstanceArr: reportInstance }) => {
          dispatch("reports/updateReportInstance", reportInstance, {
            root: true
          });
          if (
            reportInstance.status === "completed" ||
            reportInstance.status === "failed"
          ) {
            dispatch(
              "notifications/getUserNotifications",
              { fetch_all: 1 },
              {
                root: true
              }
            );
          }
        }
      );
  },

  requestDashboardStats({ state, commit }) {
    if (state.selectedServiceProviderId === "all") {
      return;
    }

    loadDashboardStats(state.userApplicationViewScope).then(function(result) {
      let callVolumeKey = state.selectedAccountId;

      if (state.userApplicationViewScope === "sp") {
        callVolumeKey = "all_sp_" + state.selectedServiceProviderId;
      }

      return commit("UPDATE_CALL_VOLUME_USAGE", {
        acId: callVolumeKey,
        call_volume: result.data.call_volume_changes
      });
    });
  },

  listenRefresh({ state }, { content_model, content_id, refresh }) {
    let ac_id = state.selectedAccountId;
    echo
      .channel("refreshing_AC_" + ac_id + `_${content_model}_${content_id}`)
      .listen("RefreshContentInUseEvent", eventData => {
        refresh({
          content_id: content_id,
          refreshing: 0,
          ac_id: ac_id
        });
        let message =
          "<div style='text-overflow: ellipsis; white-space: nowrap; overflow: hidden; width: 0; min-width: 100%'>" +
          _.startCase(content_model) +
          ":&nbsp;" +
          `${eventData.content_name}` +
          "</div>";
        /* eslint-disable */
        if (eventData.refreshResult) {
          message +=
            "<div style='word-wrap: break-word;'>" +
            __("Content item usage has been successfully refreshed. Check in use is now enabled and up to date.") +
            "</div>";

          Vue.prototype.$notify({
            title: __("Success"),
            message: message,
            type: "success",
            dangerouslyUseHTMLString: true
          });
        } else {
          message +=
            "<div style='word-wrap: break-word;'>" +
            __("Usages failed to refresh. Please retry again") +
            "</div>";
          Vue.prototype.$notify.error({
            title: __("Error"),
            message: message,
            dangerouslyUseHTMLString: true
          });
        }
        /* eslint-enable */
        echo.leave(
          "refreshing_AC_" + ac_id + `_${content_model}_${content_id}`
        );
      });
  },

  getAnnouncementsToShowInDashboard({ dispatch }) {
    return new Promise((resolve, reject) => {
      let dashboardChannel = echo.channel("dashboard");

      dashboardChannel.listen("AnnouncementsToShow", ({ announcements }) => {
        dispatch("setAnnouncements", announcements);
      });

      if (can("system.dashboard-contents.write")) {
        dashboardChannel.listen(
          "LiveContentsStats",
          ({ countTutorials, countWhatsNew }) => {
            dispatch(
              "announcements/setLiveAnnouncementsStats",
              { countTutorials, countWhatsNew },
              { root: true }
            );
          }
        );
      }

      getLatestAnnouncements()
        .then(({ data }) => {
          dispatch("setAnnouncements", data.data);
          resolve(data.data);
        })
        .catch(err => reject(err));
    });
  },

  setAnnouncements({ commit }, announcements) {
    commit(
      "SET_ANNOUNCEMENTS",
      _(announcements)
        .groupBy("announcement_type")
        .value()
    );
  },

  updateVirtualAgentUsage({ commit }, { acId, usage }) {
    commit("UPDATE_VIRTUAL_AGENT_USAGE", { acId, usage });
  },

  updateChatbotUsage(
    { commit },
    {
      acId,
      usagePercentage,
      usagesLeft,
      isUsingBurstUsages,
      burstUsagesLeft,
      featureBurstLimit
    }
  ) {
    commit("UPDATE_CHATBOT_USAGE", {
      acId,
      usagePercentage,
      usagesLeft,
      isUsingBurstUsages,
      burstUsagesLeft,
      featureBurstLimit
    });
  },

  initializeVirtualAgentUsagesForAccessibleAccounts({ commit, getters }) {
    return new Promise(resolve => {
      commit("INITIALIZE_VIRTUAL_AGENT_USAGE", getters.accountIds);
      resolve(getters.accountIds);
    });
  },

  initializeChatbotUsagesForAccessibleAccounts({ commit, getters }) {
    return new Promise(resolve => {
      commit("INITIALIZE_CHATBOT_USAGE", getters.accountIds);
      resolve(getters.accountIds);
    });
  },

  initializeCallVolumeSinceLastMonthForAccessibleAccounts({ commit, getters }) {
    return new Promise(resolve => {
      commit("INITIALIZE_CALL_VOLUME", getters.accountIds);
      resolve(getters.accountIds);
    });
  },

  async changeUserSelectedServiceProviderId(
    { commit, state, getters, dispatch },
    serviceProviderId
  ) {
    let serviceProviderExists = getters.serviceProviderIds.includes(
      serviceProviderId
    );

    if (serviceProviderId !== "all" && !serviceProviderExists) {
      // move the user to restricted pagetimezone_ac_sp
      router.push("/404").catch(err => {
        console.log(err);
      });
    } else {
      serviceProviderId = serviceProviderExists ? serviceProviderId : "all";

      if (state.selectedServiceProviderId !== serviceProviderId) {
        dispatch("expressionbuilder/clearCopyExpressions", null, {
          root: true
        });
        dispatch("canvas/resetCopyState", null, { root: true });
        dispatch("nodeLoggingProfiles/resetInitialState", null, { root: true });
        dispatch("apikeys/resetInitialState", null, { root: true });
      }

      commit("CHANGE_SELECTED_SERVICE_PROVIDER", serviceProviderId);
      let user = Vue.auth.user();
      if (user.roles) {
        let roles_with_scope = user.roles_with_scope;
        if (roles_with_scope[0]["scope"] === "system") {
          commit("SET_USER_ROLE", user.roles[0]);
        } else if (serviceProviderId !== "all") {
          let role = roles_with_scope.find(roleObj => {
            return roleObj["sp_id"] === serviceProviderId;
          });
          commit("SET_USER_ROLE", role["name"]);
        } else {
          commit("SET_USER_ROLE", null);
        }
      }
      await dispatch("theme/getThemeColors", null, { root: true });

      const serviceProvider = getters.selectedServiceProvider;
      let userScope;
      if (!serviceProvider) {
        userScope = _.map(state.userAccountSwitcherOptions, "scope").includes(
          "sp"
        )
          ? "sp"
          : "ac";
      } else {
        userScope = serviceProvider.scope;
      }

      let accountId = "all";

      if (getters.userAccountsFor(serviceProviderId).length === 1) {
        accountId = getters.userAccountsFor(serviceProviderId)[0].ac_id;
      }

      if (serviceProviderId === "all" || accountId !== "all") {
        await dispatch("changeUserSelectedAccountId", accountId);
      }

      if (state.userType !== userScope) {
        commit("SET_APP_USER_TYPE_AS", userScope);
        await routerUtils.resetRoutes();
      }
    }

    await session.set("appState", state);
  },

  async getEnabledFeaturesForUser({ commit, state }, accountId) {
    if (accountId === "all") {
      accountId = _.get(
        state,
        "userAccountSwitcherOptions[0].accounts[0].ac_id",
        "all"
      );
      // accountId = state.userAccountSwitcherOptions[0]["accounts"][0]["ac_id"];
    }
    return new Promise((resolve, reject) => {
      getEnabledFeaturesForUser(accountId)
        .then(async ({ data }) => {
          commit("SET_ENABLED_FEATURES", data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {});
    });
  },

  sortAccountSwitcherOptions({ commit, state }) {
    let sortedOptions = _.orderBy(state.userAccountSwitcherOptions, [
      sp => sp.sp_name.toLowerCase()
    ]);
    let accountsData = [];
    _.map(sortedOptions, dataItem => {
      accountsData = dataItem.accounts;
      if (accountsData.length) {
        accountsData = _.orderBy(accountsData, [
          ac => ac.ac_name.toLowerCase()
        ]);
        dataItem.accounts = accountsData;
      }
      return dataItem;
    });

    if (state.selectedAccountId && state.selectedServiceProviderId) {
      sortedOptions = _.sortBy(sortedOptions, ({ sp_id }) =>
        sp_id === state.selectedServiceProviderId ? 0 : 1
      );
      if (sortedOptions.length && state.selectedAccountId !== "all") {
        sortedOptions[0].accounts = _.sortBy(
          sortedOptions[0].accounts,
          ({ ac_id }) => (ac_id === state.selectedAccountId ? 0 : 1)
        );
      }
    }
    commit("SET_USER_ACCOUNT_SWITCHER_OPTIONS", sortedOptions);
  },

  changeUserSelectedAccountId({ commit, state, dispatch, getters }, accountId) {
    let selectedAccount = _.find(
      getters.userAccountsFor(state.selectedServiceProviderId),
      account => account.ac_id === accountId
    );

    let accountExists = !_.isEmpty(selectedAccount);

    accountId = accountExists ? accountId : "all";
    if (state.selectedAccountId !== accountId) {
      // leave previous account
      echo.leave("reports." + state.selectedAccountId);

      dispatch("expressionbuilder/clearCopyExpressions", null, {
        root: true
      });
      dispatch("canvas/resetCopyState", null, { root: true });
      dispatch("nodeLoggingProfiles/resetInitialState", null, { root: true });
      dispatch("apikeys/resetInitialState", null, { root: true });
    }

    commit("CHANGE_SELECTED_ACCOUNT", accountId);

    let user = Vue.auth.user();
    commit(
      "SET_JURISDICTION_ID",
      // eslint-disable-next-line no-prototype-builtins
      user.meta.hasOwnProperty("jurisdiction_id")
        ? user.meta.jurisdiction_id
        : ""
    );
    gainsightpx.setCustomAttribute("id", accountId);
    gainsightpx.setGainSightIdentityUserObject(user);
    gainsightpx.set();

    // join newly selected account reports channel
    dispatch("subscribeToReports");

    commit("SET_DISPLAY_DATE_FORMAT", {
      user,
      getters
    });

    commit("SET_TIMEZONE", {
      user,
      getters
    });

    // add the calls to update the timezone and display date format here
    //If the selected account changed, then get the new list of tts voices that the selected account has access to
    dispatch("accountTtsVoices/getAccountTtsVoices", accountId, {
      root: true
    });
    // change selected ac_domain info for newly selected account
    let ac_domain = accountExists
      ? _.get(selectedAccount, "ac_domain_map")
      : null;
    commit("CHANGE_SELECTED_ACCOUNT_DOMAIN", ac_domain);

    dispatch("sortAccountSwitcherOptions");

    commit(
      "SET_USER_SCOPE_IN_APPLICATION",
      state.selectedAccountId !== "all" ? "ac" : "sp"
    );

    dispatch("requestDashboardStats");

    session.set("appState", state);
  },

  resetAppState({ commit }) {
    commit("SET_USER_ACCOUNT_SWITCHER_OPTIONS", []);
    commit("CHANGE_SELECTED_SERVICE_PROVIDER", "all");
    commit("CHANGE_SELECTED_ACCOUNT", "all");
    commit("SET_USER_SCOPE_IN_APPLICATION", "");
    commit("SET_JURISDICTION_ID", "");
    commit("SET_APP_USER_TYPE_AS", null);
    commit("SET_LOGGED_IN_USER_EMAIL", "");
    commit("SET_CT_ENABLED", false);
    // in case of automatic logout if user is in canvas mode
    commit("TOGGLE_CANVAS_MODE", false);

    // in case you have to reset or change any module level store state while logging out
    commit("businessplans/SET_BUSINESS_PLAN_UPDATE_FLAG", false, {
      root: true
    });
    commit("nodeTypes/RESET_ACCOUNT_TO_NODE_TYPES_MAP", null, { root: true });
    commit("SET_GLOBAL_CONTENT_MODAL_IS_OPEN", false);
  },
  toggleDarkTheme({ commit, state }) {
    commit("TOGGLE_DARK_THEME");
    session.set("appState", state);
  },
  setGlobalContentModalIsOpen({ commit }, val) {
    commit("SET_GLOBAL_CONTENT_MODAL_IS_OPEN", val);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
