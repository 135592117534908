module.exports = {
  request: function(req, token) {
    this.options.http._setHeaders.call(this, req, {
      Authorization: "Bearer " + token
    });
  },

  response: function(res) {
    let headers = this.options.http._getHeaders.call(this, res),
      token = headers.Authorization || headers.authorization;
    if (
      !res.data.pending_2fa_setup &&
      res.data.otp_required &&
      this.requireOtp
    ) {
      this.requireOtp(res.data.token);
      return false;
    } else {
      if (token) {
        // eslint-disable-next-line no-useless-escape
        token = token.split(/Bearer\:?\s?/i);

        return token[token.length > 1 ? 1 : 0].trim();
      }
    }
  }
};
