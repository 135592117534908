import Vue from "vue";
import store from "@/store";
//import session from "@/utils/localStorageManager";
import { getQueryParams, parseParams } from "@/utils/transformers";
import request from "@/utils/studio7ApiService";
import router from "@/router";
import _ from "lodash";
import util from "store/src/util";
import { REMEMBER_ME_COOKIE } from "@/constants/cookies";

export function login(credentials) {
  return Vue.auth.login({
    data: credentials,
    success: response => {
      if (!response.data.otp_required) {
        store.dispatch("app/generateUserAccountSwitcherOptions");
      }
    }
  });
}

export function fetchUser() {
  return Vue.auth.fetch({
    success: () => {
      store.dispatch("app/generateUserAccountSwitcherOptions");
    }
  });
}

export function logout() {
  return Vue.auth.logout().then(() => {
    //setting the cookie like this will work in an iFrame. It did not work in an iframe when using the cookieStorage
    util.Global.document.cookie = REMEMBER_ME_COOKIE;
  });
}

export function socialProviderAuth(provider, redirectUrl = "") {
  Vue.auth.oauth2({
    provider,
    rememberMe: false,
    params: {
      state: { redirect: redirectUrl }
    },
    redirect: false
  });
}

export function verifySocialProviderAuth(provider, hash, dataExtra) {
  if (dataExtra === undefined || dataExtra === null) {
    dataExtra = {};
  }

  if (dataExtra.constructor.name !== "Object") {
    dataExtra = {};
  }

  return new Promise((resolve, reject) => {
    let data = parseParams(hash);
    Vue.auth.oauth2({
      provider,
      code: true,
      data: {
        ...data,
        ...dataExtra
      },
      redirect: false,
      success: async () => {
        let obj = {};
        if (data["redirect"]) {
          let searchParams = getQueryParams(data["redirect"]);
          let ac_id = searchParams.get("ac_id");
          let sp_id = searchParams.get("sp_id");
          if (sp_id) {
            obj.sp_id = sp_id;
          }
          if (ac_id) {
            obj.ac_id = ac_id;
          }
        }
        if (!_.isEmpty(obj)) {
          await store.dispatch("app/generateUserAccountSwitcherOptions", obj);
        } else {
          await store.dispatch("app/generateUserAccountSwitcherOptions");
        }

        if (!data["redirect"]) {
          router.push("/dashboard").catch(() => {});
        } else {
          router.push(data["redirect"]).catch(() => {});
        }
        resolve(data);
      },
      error: error => {
        reject({
          error: error.response.data.error,
          redirect: data["redirect"]
        });
      }
    });
  });

  // return Vue.auth.login({
  //   url: process.env.VUE_APP_BACKEND_BASE_API + "/auth/callback/google",
  //   data: { access_token: data.access_token },
  //   success: () => {
  //     store.dispatch("app/generateUserAccountSwitcherOptions");
  //   }
  // });
}

export function verifyOtp(credentials) {
  return request({
    url: "/auth/verify/otp",
    method: "post",
    data: credentials
  });
}
