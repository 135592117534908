import {
  createVariable,
  deleteVariable,
  fetchVariables,
  updateVariable
} from "@/api/variables";
import { fetchCAVVariables, fetchCAVVariableGroups } from "@/api/cavVariables";
import _ from "lodash";
import __ from "@/translation";
import {
  cancelPendingRequests,
  generateCancelToken,
  isErrorDueToCancelledToken
} from "@/utils/studio7ApiService";
import { FEATURE_FLAG_KEYS } from "@/constants/launch-darkly/featureFlagKeys";
import { checkFeature } from "@/utils/ldCheckUtil";

const formatVariables = (accountVariableMap, variables, ac_id) => {
  let variableCollection = _.find(accountVariableMap, {
    ac_id
  });

  if (variableCollection === undefined) {
    accountVariableMap.push({
      ac_id,
      variables
    });
  } else {
    variableCollection.variables = variables;
  }
  return accountVariableMap;
};

const formatCAVVariables = (variables, ac_id, variableGroupsMap) => {
  if (variableGroupsMap.size === 0) {
    return [];
  }
  return variables.map(variable => ({
    variable_id: Number(variable.variableTypeId),
    variable_name:
      variableGroupsMap.get(variable.variableGroup.variableGroupId) +
      "." +
      variable.name,
    default_value:
      variable.restrictions.defaultValues.length > 0
        ? variable.restrictions.defaultValues[0]
        : "",
    passed_parameter: false, // Assuming this is always false
    variable_type: "cav", // Assuming this is always "single_value"
    scope: "session", // Assuming this is always "session"
    ac_id: ac_id,
    is_enabled: 1, // Assuming this is always 1
    is_archived: 0, // Assuming this is always 0
    is_refreshing: 0 // Assuming this is always 0
  }));
};

const fetchVariableGroups = (options, selectedAccountDomain, cancelToken) => {
  return fetchCAVVariableGroups(
    options,
    selectedAccountDomain,
    cancelToken
  ).then(({ data: variableGroupsData }) => {
    return new Map(
      variableGroupsData.items.map(group => [group.variableGroupId, group.name])
    );
  });
};

const fetchVariablesAndCAV = (
  options,
  selectedAccountId,
  selectedAccountDomain,
  variableGroupsMap,
  cancelToken,
  dispatch
) => {
  return Promise.allSettled([
    fetchVariables(options, cancelToken),
    fetchCAVVariables(options, selectedAccountDomain, cancelToken)
  ]).then(results => {
    const variablesResponse =
      results[0].status === "fulfilled"
        ? results[0].value
        : { data: { data: [] } };
    const domainVariablesResponse =
      results[1].status === "fulfilled"
        ? results[1].value
        : { data: { items: [] } };

    if (
      results[0].status !== "fulfilled" &&
      !isErrorDueToCancelledToken(results[0].reason)
    ) {
      dispatch("setupNotification", {
        title: __("Fetch Variables failed"),
        type: "error",
        message: results[0].reason.response?.statusText || __("Unknown error")
      });
    }

    if (
      results[1].status !== "fulfilled" &&
      !isErrorDueToCancelledToken(results[0].reason)
    ) {
      dispatch("setupNotification", {
        title: __("Fetch CAV Variables failed"),
        type: "error",
        message: results[1].reason.response?.statusText || __("Unknown error")
      });
    }

    return [
      variablesResponse.data.data,
      formatCAVVariables(
        domainVariablesResponse.data.items,
        selectedAccountId,
        variableGroupsMap
      )
    ];
  });
};

export const state = {
  accountVariablesMap: [],
  loading: false,
  cancelTokens: [],
  showNotification: false,
  notificationContent: {
    title: "",
    message: "",
    type: "success"
  }
};

export const getters = {
  variables(state, getters, rootState) {
    let variables = _.find(state.accountVariablesMap, {
      ac_id: rootState.app.selectedAccountId
    });
    if (variables) {
      return variables.variables;
    } else {
      return [];
    }
  },

  getVariablesDefaultValue(state, getters) {
    return id => {
      let variable = _.find(getters.variables, variable => {
        return variable.variable_id === id;
      });
      return variable ? variable.default_value : "";
    };
  },

  variableIds(state, getters) {
    return _.map(getters.variables, variable =>
      variable.variable_id.toString()
    );
  },

  getVariablesOfType(state, getters) {
    return type => {
      return _.filter(
        getters.variables,
        variable => variable.variable_type === type
      );
    };
  },

  variablesCollectionOfTypes(state, getters) {
    return types => {
      let variables = [];
      _.each(types, type => {
        variables = _.concat(variables, getters.getVariablesOfType(type));
      });
      return variables;
    };
  },

  singleValuedVariables(state, getters) {
    return getters.getVariablesOfType("single_value");
  },

  paymentVariables(state, getters) {
    return getters.getVariablesOfType("payment");
  },

  secureVariables(state, getters) {
    return getters.getVariablesOfType("secure");
  },

  audioVariables(state, getters) {
    return getters.getVariablesOfType("audio");
  },

  systemVariables(state, getters) {
    return getters.getVariablesOfType("system");
  },

  xSipVariables(state, getters) {
    return getters.getVariablesOfType("xsip");
  },

  cavVariables(state, getters) {
    return getters.getVariablesOfType("cav");
  },

  arrayVariable(state, getters) {
    let arrayVariables = getters.getVariablesOfType("array");
    return (
      arrayVariables[0] || {
        variable_name: "studio7arrVar4Acc", // hopefully unique in the account
        variable_id: -1,
        passed_parameter: false,
        scope: "session",
        variable_type: "array",
        default_value: "",
        is_enabled: 1,
        is_refreshing: 0
      }
    );
  },

  variablesUsedInExpressionBuilder(state, getters) {
    return _.concat(
      getters.singleValuedVariables,
      getters.systemVariables,
      getters.secureVariables,
      getters.xSipVariables,
      getters.cavVariables
    );
  },

  passedParameterVariables(state, getters) {
    return _.filter(getters.getVariablesOfType("single_value"), variable => {
      return variable.passed_parameter === true;
    });
  },

  getPassedParameterNameFromId(state, getters) {
    return id => {
      let passedParameter = _.find(
        getters.passedParameterVariables,
        variable => {
          return variable.variable_id === id;
        }
      );

      return passedParameter ? passedParameter.variable_name : "";
    };
  },
  getVariableOfId(state) {
    return (ac_id, var_id) => {
      let variables = _.find(state.accountVariablesMap, {
        ac_id: ac_id
      });
      return _.cloneDeep(
        _.find(variables.variables, {
          variable_id: var_id
        })
      );
    };
  },

  singleValuedAndSecureVariables(state, getters) {
    return _.concat(getters.singleValuedVariables, getters.secureVariables);
  },

  singleValuedAndAudioAndSecureVariables(state, getters) {
    return _.concat(
      getters.singleValuedVariables,
      getters.audioVariables,
      getters.secureVariables
    );
  },

  singleValuedAndAudioAndSecureVariablesNoGroup(state, getters) {
    //No need to display variables separately for type
    return getters.variables;
  },
  variablesExceptArrayVariables(state, getters) {
    return _.reject(
      getters.variables,
      variable => variable.variable_type === "array"
    );
  },

  arrayVariableName(state, getters) {
    return getters.arrayVariable.variable_name;
  },

  getVariableNamesOfType() {
    return variables =>
      _.map(_.orderBy(variables, ["updated_at"], ["desc"]), "variable_name");
  },

  variableNames(state, getters) {
    return getters.getVariableNamesOfType(getters.singleValuedVariables);
  },

  secureVariableNames(state, getters) {
    return getters.getVariableNamesOfType(getters.secureVariables);
  },

  paymentVariableNames(state, getters) {
    return getters.getVariableNamesOfType(getters.paymentVariables);
  },

  getSystemVariableNames(state, getters) {
    return getters.getVariableNamesOfType(getters.systemVariables);
  },

  audioVariableNames(state, getters) {
    return getters.getVariableNamesOfType(getters.audioVariables);
  },

  xSipVariableNames(state, getters) {
    return getters.getVariableNamesOfType(getters.xSipVariables);
  },

  cavVariablesNames(state, getters) {
    return getters.getVariableNamesOfType(getters.cavVariables);
  },

  getSystemVariableIdFromName: (state, getters) => variable_name => {
    return getters.getVariableIdFromNameAndType(variable_name, "system");
  },

  getXSipVariableIdFromName: (state, getters) => variable_name => {
    return getters.getVariableIdFromNameAndType(variable_name, "xsip");
  },

  getVariableIdFromNameAndType: (state, getters) => (
    variable_name,
    variable_type
  ) => {
    let variable = _.find(getters.getVariablesOfType(variable_type), {
      variable_name
    });
    return _.get(variable, "variable_id", variable_name);
  },

  loading: state => {
    return state.loading;
  }
};

export const mutations = {
  SET_VARIABLES_FOR_ACCOUNT(state, variableAccountMap) {
    state.accountVariablesMap = variableAccountMap;
  },

  SET_SHOW_NOTIFICATION_STATUS(state, flag) {
    state.showNotification = flag;
  },

  SET_NOTIFICATION_CONTENT(state, content) {
    content.customClass = `${content.customClass ||
      ""} call-flow-editor-notification call-flow-editor-notification-left`;
    state.notificationContent = { position: "top-left", ...content };
  },

  ADD_VARIABLE(state, variable) {
    const variableCollection = _.find(state.accountVariablesMap, {
      ac_id: variable.ac_id
    });

    if (variableCollection !== undefined) {
      variableCollection.variables.unshift(variable);
    } else {
      state.accountVariablesMap.push({
        ac_id: variable.ac_id,
        variables: [variable]
      });
    }
  },

  EDIT_VARIABLE(state, variable) {
    const variableCollection = _.find(state.accountVariablesMap, {
      ac_id: variable.ac_id
    });

    if (variableCollection !== undefined) {
      const index = _.findLastIndex(variableCollection.variables, {
        variable_id: variable.variable_id
      });

      if (index === -1) {
        variableCollection.variables.unshift(variable);
      } else {
        variableCollection.variables.splice(index, 1, variable);
      }
    } else {
      state.accountVariablesMap.push({
        ac_id: variable.ac_id,
        variables: [variable]
      });
    }
  },

  DELETE_VARIABLE(state, variable) {
    const variableCollection = _.find(state.accountVariablesMap, {
      ac_id: variable.ac_id
    });

    if (variableCollection !== undefined) {
      const index = _.findLastIndex(variableCollection.variables, {
        variable_id: variable.variable_id
      });

      variableCollection.variables.splice(index, 1);
    }
  },

  SET_LOADING_STATE(state, isLoading) {
    state.loading = isLoading;
  },

  ADD_TO_CANCEL_TOKENS(state, token) {
    state.cancelTokens.push(token);
  },

  SET_CANCEL_TOKENS(state, tokens) {
    state.cancelTokens = tokens;
  }
};

export const actions = {
  setLoading({ commit }, value) {
    commit("SET_LOADING_STATE", value);
  },

  resetVariables({ commit }) {
    commit("SET_VARIABLES_FOR_ACCOUNT", []);
  },

  fetchVariables({ commit, state, rootState, dispatch }) {
    return new Promise(resolve => {
      if (
        _.find(state.accountVariablesMap, {
          ac_id: rootState.app.selectedAccountId
        })
      ) {
        resolve(state.accountVariablesMap);
      } else {
        commit("SET_LOADING_STATE", true);
        Promise.resolve(dispatch("forceFetchVariables")).then(() => {
          commit("SET_LOADING_STATE", false);
          resolve(state.accountVariablesMap);
        });
      }
    });
  },

  async forceFetchVariables({ commit, state, rootState, dispatch }, options) {
    //Check if there are any previous pending requests
    cancelPendingRequests(state.cancelTokens);

    // get a new cancel token
    let cancelToken = generateCancelToken();
    commit("ADD_TO_CANCEL_TOKENS", cancelToken);
    const intVariablesEnabled = await checkFeature(
      FEATURE_FLAG_KEYS.STUDIO_INTERACTION_VARIABLES
    );
    const domainId = _.get(rootState.app.selectedAccountDomain, "domain_id");
    commit("SET_LOADING_STATE", true);
    if (intVariablesEnabled && domainId) {
      const variableGroupsResult = await Promise.allSettled([
        fetchVariableGroups(options, domainId, cancelToken.token)
      ]);

      return new Promise((resolve, reject) => {
        const fetchData = async () => {
          try {
            let variables, cavVariables;
            if (variableGroupsResult[0].status === "fulfilled") {
              const variableGroupsMap = variableGroupsResult[0].value;
              [variables, cavVariables] = await fetchVariablesAndCAV(
                options,
                rootState.app.selectedAccountId,
                domainId,
                variableGroupsMap,
                cancelToken.token,
                dispatch
              );
            } else {
              dispatch("setupNotification", {
                title: __("Fetch Variable Groups failed"),
                type: "error",
                message:
                  variableGroupsResult[0].reason.response?.statusText ||
                  __("Unknown error")
              });
              const variablesResponse = await fetchVariables(
                options,
                cancelToken.token
              );
              variables = variablesResponse.data?.data || [];
              cavVariables = [];
            }

            const formattedVariables = formatVariables(
              _.cloneDeep(state.accountVariablesMap),
              [...variables, ...cavVariables],
              rootState.app.selectedAccountId
            );
            commit("SET_VARIABLES_FOR_ACCOUNT", formattedVariables);
            commit("SET_LOADING_STATE", false);
            commit("SET_CANCEL_TOKENS", []);
            resolve();
          } catch (err) {
            if (!isErrorDueToCancelledToken(err)) {
              commit("SET_LOADING_STATE", false);
              reject(err);
            }
          }
        };
        fetchData();
      });
    } else {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING_STATE", true);
        fetchVariables(options, cancelToken.token)
          .then(({ data }) => {
            const variables = formatVariables(
              _.cloneDeep(state.accountVariablesMap),
              data.data,
              rootState.app.selectedAccountId
            );
            commit("SET_VARIABLES_FOR_ACCOUNT", variables);
            commit("SET_LOADING_STATE", false);
            commit("SET_CANCEL_TOKENS", []);
            resolve(data);
          })
          .catch(err => {
            if (!isErrorDueToCancelledToken(err)) {
              commit("SET_LOADING_STATE", false);
            }
            reject(err);
          });
      });
    }
  },

  createVariable({ commit }, variable) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING_STATE", true);
      if (
        !["audio", "single_value", "secure"].includes(variable.variable_type)
      ) {
        delete variable.default_value;
      }

      if (variable.scope !== "session") {
        variable.passed_parameter = false;
      }

      createVariable(variable)
        .then(({ data }) => {
          commit("ADD_VARIABLE", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("SET_LOADING_STATE", false);
        });
    });
  },

  refreshVariable({ commit, getters }, { content_id, refreshing, ac_id }) {
    let variable = getters.getVariableOfId(ac_id, content_id);
    variable.is_refreshing = refreshing;
    commit("EDIT_VARIABLE", variable);
  },

  updateVariable({ commit }, variable) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING_STATE", true);
      if (variable.default_value) {
        variable.default_value = variable.default_value.trim();
      }

      if (
        !["single_value", "audio", "secure"].includes(variable.variable_type) &&
        variable.default_value
      ) {
        variable.default_value = "";
      }

      if (variable.scope !== "session") {
        variable.passed_parameter = false;
      }

      updateVariable(variable)
        .then(({ data }) => {
          commit("EDIT_VARIABLE", data.data);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("SET_LOADING_STATE", false);
        });
    });
  },

  undoDeleteVariable({ commit }, variable) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING_STATE", true);
      variable.is_enabled = 1; // just to make sure, it should always have is_enabled value as 1
      // since the criteria for fetching variables in the first place is objects having is_enabled = 1
      updateVariable(variable)
        .then(data => {
          commit("SET_LOADING_STATE", false);
          resolve(data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },

  deleteVariable({ commit }, variable) {
    return new Promise((resolve, reject) => {
      commit("SET_LOADING_STATE", true);
      deleteVariable(variable)
        .then(res => {
          commit("DELETE_VARIABLE", variable);
          resolve(res);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => {
          commit("SET_LOADING_STATE", false);
        });
    });
  },
  setNotificationContent({ commit }, content) {
    commit("SET_NOTIFICATION_CONTENT", content);
  },
  setupNotification({ dispatch }, content) {
    dispatch("setNotificationContent", content);
    dispatch("setShowNotificationStatus", true);
  },

  setShowNotificationStatus({ commit }, flag) {
    commit("SET_SHOW_NOTIFICATION_STATUS", flag);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
