import {
  openVisualFormSession,
  submitVisualFormSession,
  closeVisualFormSession
} from "@/api/nodes";
import { ac_headers } from "@/utils/studio7ApiService";

const state = {
  message: "",
  contentId: null,
  submitted: false,
  interactionsUrl: "",
  validForm: true
};

export const actions = {
  /**
   * set the interactions url
   * @param commit
   * @param url
   */
  setInteractionsUrl({ commit }, url) {
    commit("SET_INTERACTIONS_URL", url);
  },

  /**
   * api call to open, triggers the visual form opened event
   * @param commit
   * @param dispatch
   * @param url
   * @param call_uuid
   * @returns {*}
   */
  openVisualForm({ commit, dispatch }, { url, call_uuid }) {
    return openVisualFormSession(url, {
      data: { call_uuid: call_uuid, ac_headers: ac_headers() }
    })
      .then(() => {
        // visual form opened will trigger data load through websocket, nothing more to do here
      })
      .catch(error => {
        setTimeout(() => {
          dispatch("app/toggleOptionsLoading", false, { root: true });
          commit("SET_MESSAGE", error.message);
        }, 500);
      });
  },

  /**
   * make an api call to submit the visual form and update local and global loading state
   * @param commit
   * @param dispatch
   * @param url
   * @param data
   * @returns {*}
   */
  submitVisualForm({ commit, dispatch }, { url, data }) {
    this.contentId = data.payload.button;
    dispatch("app/toggleOptionsLoading", true, { root: true });
    commit("SET_SUBMITTED", true);

    return submitVisualFormSession(url, data)
      .then(({ data }) => {
        commit("SET_MESSAGE", data.message);
        setTimeout(() => {
          dispatch("app/toggleOptionsLoading", false, { root: true });
        }, 500);
      })
      .catch(error => {
        setTimeout(() => {
          dispatch("app/toggleOptionsLoading", false, { root: true });
          commit("SET_MESSAGE", error.message);
        }, 500);
      });
  },

  /**
   * api call to close
   * @param commit
   * @param dispatch
   * @param url
   * @param data
   * @param uuid
   */
  closeVisualForm({ commit, dispatch }, { url, data }) {
    return closeVisualFormSession(url, data)
      .then(() => {
        //nothing more to do here
      })
      .catch(error => {
        setTimeout(() => {
          dispatch("app/toggleOptionsLoading", false, { root: true });
          commit("SET_MESSAGE", error.message);
        }, 500);
      });
  },

  /**
   * set submitted
   * @param commit
   * @param submitted
   */
  setSubmitted({ commit }, submitted) {
    commit("SET_SUBMITTED", submitted);
  },

  /**
   * set valid from
   * @param commit
   * @param validForm
   */
  setValidForm({ commit }, validForm) {
    commit("SET_VALID_FORM", validForm);
  },

  /**
   * set message
   * @param commit
   * @param message
   */
  setMessage({ commit }, message) {
    commit("SET_MESSAGE", message);
  }
};

export const mutations = {
  /**
   * set message
   * @param state
   * @param message
   * @constructor
   */
  SET_MESSAGE(state, message) {
    state.message = message;
  },
  /**
   * set submitted
   * @param state
   * @param submitted
   * @constructor
   */
  SET_SUBMITTED(state, submitted) {
    state.submitted = submitted;
  },
  /**
   * set VALID FORM
   * @param state
   * @param validForm
   * @constructor
   */
  SET_VALID_FORM(state, validForm) {
    state.validForm = validForm;
  },

  /**
   * set interaction url
   * @param state
   * @param interactionsUrl
   * @constructor
   */
  SET_INTERACTIONS_URL(state, interactionsUrl) {
    state.interactionsUrl = interactionsUrl;
  }
};

export const getters = {
  message: state => state.message,
  submitted: state => state.submitted,
  validForm: state => state.validForm,
  contentId: state => state.contentId,
  interactionsUrl: state => state.interactionsUrl
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
