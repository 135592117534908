import Vue from "vue";
import router from "./router";
import _ from "lodash";
import storage from "@/utils/localStorageManager";
import studio7ApiService from "./utils/studio7ApiService";
import authdriver from "./auth/authdriver";
import VueAxios from "vue-axios";
Vue.use(VueAxios, studio7ApiService);

Vue.router = router;

export const vueAuthConfig = {
  auth: authdriver,
  http: require("@websanova/vue-auth/drivers/http/axios.1.x.js"),
  router: require("@websanova/vue-auth/drivers/router/vue-router.2.x.js"),
  tokenDefaultName: "studio7-user-token",
  tokenStore: ["localStorage"],
  authRedirect: { name: "Login" },
  notFoundRedirect: false,
  stores: ["storage", "cookie"],
  rolesVar: "roles",
  loginData: {
    url: "/auth/login",
    method: "POST",
    redirect: false
  },
  logoutData: {
    url: "/auth/logout",
    method: "POST",
    redirect: { name: "Login" },
    makeRequest: true
  },
  fetchData: {
    url: "/auth/user",
    method: "GET",
    headers: { subdomain: storage.get("subdomain") },
    enabled: true
  },
  refreshData: {
    url: "/auth/refresh",
    method: "POST",
    enabled: true,
    interval: 8
  },
  oktaData: {
    url: "/auth/callback/okta",
    method: "POST"
  },
  googleData: {
    url: "/auth/callback/google",
    method: "POST",
    redirect: false
  },
  googleOauth2Data: {
    url: "https://accounts.google.com/o/oauth2/v2/auth",
    params: {
      redirect_uri: function() {
        return this.options.getUrl() + "/login/google/callback";
      },
      client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      scope: "profile email openid",
      response_type: "token id_token",
      prompt: "select_account",
      nonce: Math.floor(Date.now() / 1000)
    }
  },
  five9Data: {
    url: "/auth/callback/five-nine",
    method: "POST",
    redirect: false
  },
  idpCrossLaunchData: {
    url: "/launch",
    method: "POST",
    redirect: false
  },
  parseUserData: function(data) {
    return Object.keys(data).length
      ? {
          ...data.data,
          roles: _.map(data.data.roles, role => role.name),
          roles_with_scope: _.map(data.data.roles, role => {
            return {
              name: role.name,
              scope: role.scope,
              sp_id: role.sp_id,
              ac_id: role.scope === "ac" ? role.pivot.model_id : null
            };
          }),
          meta: data.meta
        }
      : {};
  }
};

Vue.use(require("@websanova/vue-auth"), vueAuthConfig);
