import request, { ac_headers } from "@/utils/studio7ApiService";

export function getTasks(options = { fetch_all: 1 }, cancelToken = undefined) {
  const params = new URLSearchParams(options);
  return request({
    url: "/studio/tasks?" + params.toString(),
    method: "get",
    cancelToken: cancelToken,
    headers: ac_headers()
  });
}

export function getPhoneNumberSummary(options) {
  const params = new URLSearchParams(options);
  return request({
    url: "/studio/number-usage?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function getTasksForAccounts(spId, accIds, taskType) {
  return request({
    url: "/virtualagentusage/get-tasks-for-accounts",
    method: "post",
    data: { sp_id: spId, ac_ids: accIds, task_type: taskType }
  });
}

export function getTask(task_id) {
  return request({
    url: `/studio/tasks/${task_id}`,
    method: "get",
    headers: ac_headers()
  });
}

export function getVersions(task_id) {
  return request({
    url: `/studio/versions/${task_id}`,
    method: "get",
    headers: ac_headers()
  });
}

export function createNewVersion(task_id, data, async = false) {
  let config = {
    url: `/studio/versions/untitled-version/${task_id}`,
    method: "post",
    data,
    headers: ac_headers()
  };

  if (async) {
    data = { ...data, async };
    config["data"] = data;
  }
  return request(config);
}

export function decodeVersion(version_id) {
  return request({
    url: `/studio/decode-version/${version_id}`,
    method: "post",
    headers: ac_headers()
  });
}

export function restoreVersion(task_id, task_version_id, data, async = false) {
  let config = {
    url: `/studio/versions/switch/${task_id}/${task_version_id}`,
    method: "post",
    data,
    headers: ac_headers()
  };

  if (async) {
    data = { ...data, async };
    config["data"] = data;
  }
  return request(config);
}

export function renameVersion(version) {
  return request({
    url: `/studio/versions/rename/${version.version_id}`,
    method: "post",
    data: { version_name: version.version_name },
    headers: ac_headers()
  });
}

export function exportTask(task_id, exportConfig = {}, async = false) {
  let config = {
    url: `/studio/export/${task_id}`,
    method: "post",
    data: { exportConfig, async },
    headers: ac_headers()
  };

  if (!async) {
    config["responseType"] = "blob";
  }

  return request(config);
}

export function getExportedTaskFile({ task_id, request_id }) {
  return request({
    url: `/studio/export/download/${task_id}/${request_id}`,
    method: "post",
    responseType: "blob",
    headers: ac_headers()
  });
}

export function getSnapShot(task_id, async = true) {
  return request({
    url: `/studio/export/snapshot/${task_id}`,
    method: "post",
    data: { async },
    headers: ac_headers()
  });
}

export function duplicateTask(task_id) {
  return request({
    url: `/studio/tasks/duplicate/${task_id}`,
    data: { async: true },
    method: "post",
    headers: ac_headers()
  });
}

export function duplicateTaskCollection(task_id, groupName) {
  return request({
    url: `/studio/tasks/duplicate-collection/${task_id}`,
    data: { async: true, suffix: groupName },
    method: "post",
    headers: ac_headers()
  });
}

export function validateTask(name) {
  return request({
    url: `/studio/tasks/validate-name`,
    method: "post",
    data: { name },
    headers: ac_headers()
  });
}

export function createTask(data, async = false) {
  const task = { ...data, async };
  return request({
    url: "/studio/tasks",
    method: "post",
    data: task,
    headers: ac_headers()
  });
}

export function checkStatusOfTaskImportRequest({ requestId }) {
  return request({
    url: `/studio/tasks/task-import-export/${requestId}`,
    method: "get",
    headers: ac_headers()
  });
}

export function updateTask(task) {
  return request({
    url: `/studio/tasks/${task.task_id}`,
    method: "put",
    data: task,
    headers: ac_headers()
  });
}

export function deleteTask(task) {
  return request({
    url: `/studio/tasks/${task.task_id}`,
    method: "delete",
    data: task,
    headers: ac_headers()
  });
}

export function getPhoneNumbers(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: `/studio/get-phone-numbers?` + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function updateNumbers(data) {
  return request({
    url: `/studio/update-numbers`,
    method: "post",
    data: data,
    headers: ac_headers()
  });
}

export function getLanguages() {
  return request({
    url: "/prompt/languages",
    method: "get",
    headers: ac_headers()
  });
}

export function triggerWorkflow(data) {
  return request({
    url: `/studio/workflow-run-now`,
    method: "POST",
    data,
    headers: ac_headers()
  });
}

export function getWorkflowPostmanCollection(task) {
  return request({
    url: `/studio/workflow/${task.task_id}/collection`,
    method: "post",
    responseType: "blob",
    headers: ac_headers()
  });
}

export function publishTaskToLibrary(data, task_id, async) {
  if (async) {
    data = { ...data, async: true };
  }
  let config = {
    url: `/studio/task-libraries/publish/${task_id}`,
    method: "post",
    data,
    headers: ac_headers()
  };
  return request(config);
}

export function validateTemplate(name) {
  return request({
    url: `/studio/task-libraries/validate-name`,
    method: "post",
    data: { name },
    headers: ac_headers()
  });
}

export function getTemplates(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/studio/task-libraries?" + params.toString(),
    method: "get",
    headers: ac_headers()
  });
}

export function getEditHistories(task_id) {
  return request({
    url: `/studio/task-edit-histories/${task_id}`,
    method: "get",
    headers: ac_headers()
  });
}

export function requestTaskValidation(task_id) {
  return request({
    url: `/studio/task-validation/${task_id}`,
    method: "get",
    headers: ac_headers()
  });
}

export function getTaskValidation(task_id) {
  return request({
    url: `/studio/task-validation/get/${task_id}`,
    method: "get",
    headers: ac_headers()
  });
}

/**
 * Begin preparation for chatbot preview.
 * A new preview session will be created where interaction can pickup
 * @param {Number} task_id The task ID that requires chatbot preview
 * @param {String} task_key The task access key
 * @returns {Promise<{session_id: String}>}
 */
export function prepareTaskForChatbotPreview(task_id, task_key) {
  return request({
    url: `/studio/prepare-chatbot-preview`,
    method: "post",
    headers: ac_headers(),
    data: {
      task_id,
      task_key
    }
  });
}

export function getTaskCollectionCount(task_id) {
  return request({
    url: `/studio/tasks/${task_id}/collections/count`,
    method: "get",
    headers: ac_headers()
  });
}

export function validateCollectionName(name) {
  return request({
    url: `/studio/tasks/collections/validate-collection-name?task_collection_name=${name}`,
    method: "get",
    headers: ac_headers()
  });
}
